import React from 'react';
import moment from 'moment';
import { BookContainer } from "../../components/BookAppointment/styles";
import { API_ROOT } from "gatsby-env-variables";
import { fixedCharges } from '../../constants/fee';
import { Input, Select, Radio, DatePicker, Row, Col, Button, Switch, Form, message, Modal, Spin, Icon } from 'antd';

import { isDefined, isObjectEmpty, isArrayEmpty } from '../../utils/util';
import { CONSENT_TEXT, PAYMENT_ADVICE_TEXT } from '../../constants/bookingTextConstants';

const { Option } = Select;
const { confirm } = Modal;

const INITIAL_STATE = {
    fullName: '',
    email: '',
    phone: '',
    gender: '',
    dob: '',
    appointmentDate: '',
    isVideoConsultation: true,
    doctorProfile: {},
    slots: [],
    bookingInProgress: false,
    selectedSlot: '',
    razorpay_payment_id: '',
    razorpay_order_id: '',
    razorpay_signature: ''
};

class BookIndex extends React.Component {
    state = INITIAL_STATE;
    formRef = React.createRef();
    async componentDidMount() {
        const body = {
            userOrgAlias: this.props.customUrl
        };

        let customUrlResponse = await fetch(
            API_ROOT + "spotcare/getdoctordetailwithcustomurl",
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
        );
        const doctorProfile = await customUrlResponse.json();
        this.setState({ doctorProfile });
    }

    handleDobSelect = (date, dateString) => {
        this.setState({ dob: dateString });
    }
    async bookAppointment(){
        console.log("in book appointment")
        const payload = {email:this.state.email,
            name: this.state.fullName,
            phoneNO: this.state.phone,
            gender: this.state.gender, 
            dob: this.state.dob,
            age:this.ageFromDOB(this.state.dob),
            checkedIn: "false",
            preferredTime: this.state.selectedSlot,
            appointmentDate: this.state.appointmentDate,
            spotWalkin: 0,
            selectedDocUserMapping: this.state.doctorProfile.oumId,
            selectedDocId: this.state.doctorProfile.docId,
            orgId:this.state.doctorProfile.orgId,
            isVideo: this.state.isVideoConsultation.toString(),
            tzOffset:new Date().getTimezoneOffset()*-1,
            originalOrgUserMappingId:this.state.doctorProfile.oumId,
            isPaymentPending:this.state.isVideoConsultation?1:0}
         let appointmentResponse= await fetch(
            API_ROOT + "payment/customurl",
            {
              method: "POST",
              body: JSON.stringify(payload),
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            } 
        );
        const response= await appointmentResponse.json();
            console.log(response)
            if (response.status ==true){

            message.success(this.state.isVideoConsultation ? "Payment link sent to your Mobile number and Email":"Appointment booked successfully", 3, () => {
            this.formRef.current.resetFields();
            this.setState({
                fullName: '',
                email: '',
                phone: '',
                gender: '',
                dob: '',
                appointmentDate: '',
                isVideoConsultation: true,
                slots: [],
                bookingInProgress: false,
                selectedSlot: '',
                razorpay_payment_id: '',
                razorpay_order_id: '',
                razorpay_signature: ''
            });

        })}
        else{
            message.error("Not Booked! Something went wrong. Please try again");
            this.formRef.current.resetFields();
            this.setState({
                fullName: '',
                email: '',
                phone: '',
                gender: '',
                dob: '',
                appointmentDate: '',
                isVideoConsultation: true,
                slots: [],
                bookingInProgress: false,
                selectedSlot: '',
                razorpay_payment_id: '',
                razorpay_order_id: '',
                razorpay_signature: ''
            });
        };
    }


    showConsentMessage = () => {
            confirm({
                title: CONSENT_TEXT,
                okType: 'primary',
                cancelButtonProps: { type: 'danger'},
                okText: 'Agree',
                cancelText: 'Disagree',
                centered: true,
                // onOk: () => this.openRazorPay(),
                onOk:()=> this.bookAppointment(),
                onCancel: () => {this.setState({bookingInProgress: false})}
              });
      }

    getDisabledHours = () => {
        var hours = [];
        for(var i = 0; i < moment().hour(); i ++){
            hours.push(i);
        }
        return hours;
    }

    getDisabledMinutes = (selectedHour) => {
        var minutes = [];
        if (selectedHour === moment().hour()) {
            for(var i = 0; i < moment().minute(); i ++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    handleSubmit = e => {
        this.setState({ bookingInProgress: true });
          if (this.state.doctorProfile.rowsConsultation.has_opted === "1" && this.state.isVideoConsultation) {
            this.showConsentMessage();
          }
          else {
            this.bookAppointment();
          }
      };

      getSlots = body => {
        fetch(
            API_ROOT + "spotcare/slots",
            {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        ).then(response => response.json())
        .then(slotResponse => {
            this.setState({ slots: slotResponse.data });
        });
      }

      ageFromDOB = (dob) => {
        const today = new Date();
        const birthdate = new Date(dob);
        if(dob === '' || dob == null) {
          return 0;
        }
        let age = today.getFullYear() - birthdate.getFullYear();
        const m = today.getMonth() - birthdate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
          age--;
        }
        return age;
      }

    handleConsultationDateSelect = (date, dateString) => {
        this.setState({ 
            appointmentDate: dateString
        });
        
        const body = {
            addSlot: null,
            date: dateString,
            timeType:(dateString === new Date().toISOString().slice(0, 10) ? 'today' :undefined),
            tzOffset:new Date().getTimezoneOffset()*-1,
            selectedDoctorUserId: this.state.doctorProfile.oumId
        };

        this.getSlots(body);
    }

    disabledDateForDOB = (current) => {
        // Can not select days after today and today
        return current && current > moment().startOf('day');
    }

    disabledDateForConsultation = (current) => {
        // Can not select days after today and today
        return current && current < moment().startOf('day');
    }

    render() {
        
        const { doctorProfile } = this.state;
        const antIcon = <Icon type="loading" style={{ fontSize: 50 }} spin />
        let portalCharge = 0;
        let gst = 0;
        let consultationAmount = 0;

        if (isDefined(doctorProfile) && isObjectEmpty(doctorProfile))  {
            return (
                <div style={{ textAlign: 'center', margin: '20%' }}>
                    <Spin tip="Loading..." indicator={antIcon} />
                </div>
            );
        }

        const profile = doctorProfile.profile[0];
        const { orgId } = doctorProfile;
        const profileImage = profile.imgpath;
        const clinicDetails = !isArrayEmpty(doctorProfile.clinic) ? doctorProfile.clinic: [];
        if (doctorProfile.rowsConsultation.consultation_amount < 100) {
            consultationAmount = (doctorProfile.rowsConsultation.consultation_amount + fixedCharges).toFixed(2);
            portalCharge = (0).toFixed(2);
            gst = (0).toFixed(2);
        }
        else {
            consultationAmount =  (doctorProfile.rowsConsultation.consultation_amount).toFixed(2);
            portalCharge = (consultationAmount * (12.5 / 100)).toFixed(2);
            gst = (portalCharge * (18 / 100)).toFixed(2);
        }
        
        const hasOptedVideoConsultation = doctorProfile.rowsConsultation.has_opted;
        
        return (
            <BookContainer>
            <div id="bookAppointment">
                <Row>
                    <Col sm={8} md={12} lg={12}>
                        <div id="bookappointmentdoctorprofile_container">
                            <div className="profile-container">
                                <div className="profile-data-container">
                                <div className="row">
                                    <div className="col-md-5">
                                    <div className="sh-heading sh-heading-style1" id="heading-QAUZhdmpKV">
                                        <div className="mb-3">
                                        <img src={profileImage} className="profile-image-style border" alt="Doctor profile picture"
                                            style={{ maxHeight: "230px" }} />
                                        </div>
                                    </div>
                                    </div>
                                    { isDefined(orgId) ? (<div className="col-md-7">
                                        <h2 className="mon-font sh-heading-content size-xl ">
                                            <b style={{ fontFamily: "Montserrat" }}>{profile.name}</b>
                                        </h2>
                                        <div className="sh-heading" id="heading-bOatMNFGmp">
                                            <div className="sh-element-margin">
                                            <h2 className="mon-font sh-heading-content size-custom text-left mon-font" style={{ fontSize: "16px" }}>
                                                {profile.usertype}
                                            </h2>
                                            </div>
                                            <p className="mon-font">
                                                <strong>{clinicDetails[0].name}</strong>
                                                <br />
                                                <span style={{ fontSize: "14px", color: "#363636" }}>{clinicDetails[0].address}</span>
                                            </p>
                                        </div>
                                    </div>) : null}
                                    <div className="sh-empty-space vc_empty_space sh-empty-space-bPSVpZhkHG" style={{ height: "20px" }}></div>
                                        { isDefined(orgId) ? (
                                            <div className="wpb_text_column wpb_content_element">
                                            <p className="text-justify mon-font">
                                                {profile.bio}
                                            </p>
                                        </div>
                                        ) : null}
                                </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={8} md={12} lg={12}>
                        <div id="bookappointmentform_container_main">
                            <div className="form-container">
                                <div className="form-input-container">
                                <h2>Book your consultation</h2>
                                    <Form  ref={this.formRef}
                      onFinish={this.handleSubmit}
                      className="consultation-form"
                    >
                      <Row>
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              pattern: /^[a-zA-Z][a-zA-Z. ]*$/,
                              min:3,
                              message: "Please enter a valid name",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            
                            placeholder="Full Name"
                            onChange={e =>
                              this.setState({ fullName: e.target.value })
                            }
                            onKeyPress={ event =>
                              {
                                  if (!/^[a-zA-Z ]+( [a-zA-Z]+)*$/.test( event.key ) )
                                 
                                  {
                                      event.preventDefault()
                                  }
                              } }
                          required
                         
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input
                            className="input"
                          
                            placeholder="Email "
                            onChange={e =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              required: true,

                              pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                              message: "Please enter a valid phone number",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            
                            placeholder=" Phone"
                            onChange={e =>
                              this.setState({ phone: e.target.value })
                            }
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "Please select your gender!",
                            },
                          ]}
                        >
                          <Radio.Group
                            placeholder="Gender"
                            buttonStyle="solid"
                            className="select-input"
                            onChange={e =>
                              this.setState({ gender: e.target.value })
                            }
                          >
                            <Radio.Button value="male">Male</Radio.Button>
                            <Radio.Button value="female">Female</Radio.Button>
                            <Radio.Button value="other">Other</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item name=" date" rules={[{ required: false }]}>
                          <DatePicker
                            placeholder="Date of Birth (YYYY-MM-DD)"
                            className="date-input"
                            
                            disabledDate={this.disabledDateForDOB}
                            showToday={false}
                            onChange={this.handleDobSelect}
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: "Please pick your consultation date!",
                            },
                          ]}
                        >
                          <DatePicker
                            placeholder="Consultation date (YYYY-MM-DD)"
                            className="date-input"
                            
                            disabledDate={this.disabledDateForConsultation}
                            showToday={false}
                            onChange={this.handleConsultationDateSelect}
                          />
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          name="slot"
                          rules={[
                            {
                              required: true,
                              message: "Please pick your consultation slot!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Slot"
                            className="select-input1"
                             onChange={selectedSlot =>
                              this.setState({ selectedSlot })
                            }
                          > 
                            {this.state.slots.map((slot, i) => (
                              <Option key={i} value={slot}>
                                {slot}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Row>
                      {hasOptedVideoConsultation ? (
                        <>
                          <Row>
                            <Col
                              sm={{ span: 6, offset: 10 }}
                              lg={{ span: 6, offset: 15 }}
                            >
                              <Switch
                                checked={this.state.isVideoConsultation}
                                onChange={() =>
                                  this.setState({
                                    isVideoConsultation: !this.state
                                      .isVideoConsultation,
                                  })
                                }
                              />
                              <h4>Video Consultation</h4>
                            </Col>
                          </Row>
                          {this.state.isVideoConsultation ? (
                            <Row>
                              <p className="p-Down">
                                Consultation Charges: ₹ {consultationAmount} ,
                                Service fees: ₹ {portalCharge} , GST: ₹ {gst}
                              </p>
                            </Row>
                          ) : null}
                        </>
                      ) : null}
                      <Row>
                        <Col
                          sm={{ span: 6, offset: 8 }}
                          lg={{ span: 6, offset: 3 }}
                        >
                          <Button
                            loading={this.state.bookingInProgress}
                            className="submit-button-style" 
                            htmlType="submit"
                            size="large"
                            type="primary"
                            shape="round"
                            >
                            {this.state.isVideoConsultation
                              ? "Pay & Book"
                              : "Book Appointment"}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        {this.state.isVideoConsultation ? (
                          <div>
                            <span style={{ color: "#000000", fontWeight: 600 }}>
                              Important Note:&nbsp;
                            </span>
                            <span style={{ color: "#0078aa", fontWeight: 600 }}>
                              {PAYMENT_ADVICE_TEXT}
                            </span>
                          </div>
                        ) : null}
                      </Row>
                    </Form >
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            </BookContainer>
        );
    }
}


export default BookIndex;